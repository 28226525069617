// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Auth/LoginForm/LoginForm.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Auth/LoginForm/LoginForm.tsx");
  import.meta.hot.lastModified = "1738902500000";
}
// REMIX HMR END

import { Form, useActionData, useHref, useNavigation } from '@remix-run/react';
import { Lock } from 'lucide-react';
import { TextField } from '~/components/InputFields';
import { Button } from '~/components/ui/button';
import { action as loginAction } from '~/routes/($lang).auth.login';
import { action as adminAction } from '~/routes/($lang).admin_.auth.login';
export const LoginForm = ({
  renderType
}) => {
  _s();
  const navigation = useNavigation();
  const currentUrl = useHref('.');
  const loginActionType = renderType === 'Admin' ? adminAction : loginAction;
  const isSubmitting = navigation.state !== 'idle' && navigation.formAction === currentUrl;
  const loginActionData = useActionData();
  return <Form action={currentUrl} method='post' className='flex flex-wrap w-full pt-4 mx-auto max-w-md'>
      <div className='w-full block px-2 mb-2'>
        <TextField name='email' label='Email' placeholder='Enter your Email' type='email' defaultValue={loginActionData ? loginActionData?.values?.email : ''} hasError={!!loginActionData?.errors?.email} errorMessage={loginActionData?.errors?.email} required />
      </div>
      <div className='w-full block px-2 mb-2'>
        <TextField name='password' label='Password' placeholder='Enter your Password' type='password' defaultValue={loginActionData ? loginActionData?.values?.password : ''} hasError={!!loginActionData?.errors?.password} errorMessage={loginActionData?.errors?.password} required />
      </div>
      <div className='flex justify-center align-middle items-center p-2 flex-col md:flex-row w-full'>
        <Button type='submit' isProcessing={isSubmitting} className='w-full'>
          <Lock size={20} className='inline-block mr-2' />
          <span>Login</span>
        </Button>
      </div>
    </Form>;
};
_s(LoginForm, "GdfDDrvreBRm4TWQBs3ADwuCRLQ=", false, function () {
  return [useNavigation, useHref, useActionData];
});
_c = LoginForm;
var _c;
$RefreshReg$(_c, "LoginForm");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;