// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/_actions/messages/index.ts"
);
import.meta.hot.lastModified = "1738902500000";
}
// REMIX HMR END

// Common Validation Message
export const THIS_FIELD_IS_REQUIRED = 'This field is required';
export const UNKNOW_ACTION_PROVIDED = 'Unknown Action is provided';

// Unknown Login Error
export const UNKNOWN_LOGIN_ERROR = 'Please try again later';

// Common Item messages
export const ITEM_CREATED_SUCCESSFULLY = 'New item created successfully';
export const QUESTION_LINKED_SUCCESSFULLY = 'Question Linked Successfully';
export const ITEM_CREATE_FAILED =
  'Something went wrong trying to create new item';
export const ITEM_UPDATED_SUCCESSFULLY = 'Item updated successfully';
export const ITEM_UPDATE_FAILED =
  'Something went wrong trying to update an item';
export const ITEM_DELETED_SUCCESSFULLY = 'Item deleted successfully';
export const QUESTION_REMOVED_FROM_COLLECTION_SUCCESSFULLY = 'Question removed from collection successfully';
export const QUESTION_ADDED_TO_COLLECTION_SUCCESSFULLY = 'Question added to collection successfully';
export const ITEM_DELETE_FAILED =
  'Something went wrong trying to delete an item';
export const QUESTION_REMOVAL_FROM_COLLECTION_FAILED =
  'Something went wrong trying to remove question from collection';
export const QUESTION_ADDITION_TO_COLLECTION_FAILED =
  'Something went wrong trying to add question to collection';
export const ITEM_CREATE_ERROR =
  'Something went wrong trying to create new item';

export const USER_NOT_FOUND_ERROR = 'Something went wrong, User not found';
export const SOMETHING_WENT_WRONG =
  'Something went wrong, Please try again later';

// Organization Profile Messages
export const GROUP_DETAILS_UPDATED_SUCCESSFULLY =
  'Group Details updated succesfully';
export const GROUP_DETAILS_UPDATE_ERROR =
  'Something went wrong trying to update Group Details';
export const GROUP_DETAILS_USER_NOT_FOUND =
  'Something went wrong trying to update group Details. User not found';
export const GROUP_DETAILS_UNKNOWN_ERROR =
  'Something went wrong trying to update group Details';

// Emission Configuration Messages
export const EMISSION_CONFIGURATION_CREATE_FAILED =
  'Something went wrong trying to create new emission configuration';
export const EMISSION_CONFIGURATION_UPDATE_FAILED =
  'Something went wrong for updating emission Configuration';
export const EMISSION_CONFIGURATION_DELETE_FAILED =
  'Something went wrong to delete emission configuration';

export const FUEL_TYPE_CONFIGURATION_CREATED =
  'Fuel type emission configuration has been created successfully';
export const FUEL_TYPE_CONFIGURATION_UPDATED =
  'Fuel type emission configuration has been updated successfully';
export const FUEL_TYPE_CONFIGURATION_DELETED =
  'Fuel type emission configuration has been deleted successfully';

export const ELECTRICITY_CONFIGURATION_CREATED =
  'Electricity emission configuration has been created successfully';
export const ELECTRICITY_CONFIGURATION_UPDATED =
  'Electricity emission configuration has been updated successfully';
export const ELECTRICITY_CONFIGURATION_DELETED =
  'Electricity emission configuration has been deleted successfully';

export const IATA_CONFIGURATION_CREATED =
  'IATA emission configuration has been created successfully';
export const IATA_CONFIGURATION_UPDATED =
  'IATA emission configuration has been updated successfully';
export const IATA_CONFIGURATION_DELETED =
  'IATA emission configuration has been deleted successfully';

/** Emission Assessment Messages */
export const FUEL_EMISSION_CALCULATION_CREATED =
  'Fuel Emission Calculation has been created successfully';
export const ELECTRICITY_EMISSION_CALCULATION_CREATED =
  'Electricity Emission Calculation has been created successfully';
export const FRIEGHT_EMISSION_CALCULATION_CREATED =
  'Frieght Emission Calculation has been created successfully';
export const AIR_EMISSION_CALCULATION_CREATED =
  'Air Emission Calculation has been created successfully';
export const ROAD_FRIEGHT_EMISSION_CREATED =
  'Road Frieght Emission Calculation has been created successfully';
export const WASTE_EMISSION_CREATED =
  'Waste Emission Calculation has been created successfully';
export const BULK_UPLOAD_CREATED =
  'Bulk upload has been added to the queue successfully';



  /** Activity Upload Messages */
export const BUSINESS_PRODUCT_SERVICE_CREATED =
'Business Product Service has been created successfully';
export const TRANSACTION_PRODUCT_SERVICE_CREATED =
'Transaction Product Service has been created successfully';
export const TRANSACTION_EMPLOYEE_WORK_CREATED =
'Transaction Employee Work has been created successfully';
export const TRANSACTION_FINANCIALS_CREATED =
'Transaction Financials has been created successfully';
export const TRANSACTION_TRAININGS_CREATED =
'Transaction Trainings has been created successfully';
export const TRANSACTION_COMPLAINTS_CREATED =
'Transaction Complaints has been created successfully';
export const STAKE_HOLDER_MASTERS_CREATED =
'StakeHolder Master has been created successfully';