// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/_actions/authAction.ts"
);
import.meta.hot.lastModified = "1738902500000";
}
// REMIX HMR END

import { json } from '@remix-run/node';
import { ValidationError } from 'yup';

import { authenticate } from '~/api/services/auth/auth.server';
import { respondJsonWithErrorFlash } from '~/api/utilities/response.server';

import { createUserSession } from '~/api/utilities/authSession.server';
import { AUTH_PAGES_URL } from '~/lib/constants';
import { authValidationSchema } from './schemas/authSchema';

import { UNKNOWN_LOGIN_ERROR } from './messages';

import { transformYupErrorsIntoObject } from '~/lib/utils';

export const loginAction = async (request: Request) => {
  const formData = await request.formData();
  const userInput = Object.fromEntries(formData);

  try {
    const data = await authValidationSchema.validate(userInput, {
      abortEarly: false,
    });
    const authUser = await authenticate(data);
    if (authUser.id) {
      return await createUserSession(request, authUser, AUTH_PAGES_URL.APP);
    }
  } catch (error: any) {
    if (error instanceof ValidationError) {
      const errors = transformYupErrorsIntoObject(error);
      return json({ errors, values: userInput });
    }
    return respondJsonWithErrorFlash({
      request,
      error,
      message: error?.message || UNKNOWN_LOGIN_ERROR,
    });
  }
};
