// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/($lang).auth.login.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/($lang).auth.login.tsx");
  import.meta.hot.lastModified = "1738902500000";
}
// REMIX HMR END

import { AUTH_PAGES_URL } from '~/lib/constants';
import { LoginForm } from '~/components/Auth/LoginForm';
import { AnchorLink } from '~/components/AnchorLink';
import { loginAction } from '~/_actions/authAction';

// Login Action
export const action = async ({
  request,
  params
}) => await loginAction(request);
const LoginPage = () => {
  return <div className='block py-6'>
      <div className='relative p-4 md:p-8'>
        <h2 className='mt-6 text-2xl font-bold text-slate sm:text-3xl md:text-4xl px-3 md:px-6 text-center'>
          Welcome back!
        </h2>
        <p className='mt-4 leading-relaxed text-slate/90 px-3 md:px-6 text-center'>
          Login to your account to continue.
        </p>
      </div>
      <div className='relative p-4 md:p-8'>
        <LoginForm renderType='User' />
        <p className='mt-4 text-sm text-slate-500 block text-center w-full'>
          Forgot your password?
          <AnchorLink to={AUTH_PAGES_URL.FORGOTPASSWORD} className='text-slate-700 hover:text-primary-500 underline px-2'>
            Reset here
          </AnchorLink>
        </p>
      </div>
    </div>;
};
_c = LoginPage;
export default LoginPage;
var _c;
$RefreshReg$(_c, "LoginPage");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;